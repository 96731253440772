<template>
  <div class="distribution">
    <div class="content">
      <div class="title">
        <div>经销商列表</div>
        <div class="tool">
          <el-input
            v-model.trim="searchValue"
            style="width:220px;margin-right:20px"
            size="small"
            placeholder="请输入"
            >
            <i slot="suffix" class="el-input__icon el-icon-search" @click="handleSearch(1)"></i>
          </el-input>
          <el-button size="small" type="primary" icon="el-icon-plus" @click="goRoute('')">新建</el-button>
        </div>
      </div>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="序号" type="index" width="100">
            <template slot-scope="scope">
              <span>{{(page-1)*10+scope.$index+1}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="code" label="经销商ID"/>
          <el-table-column prop="name" label="公司名称"/>
          <el-table-column prop="phone" label="服务热线"/>
          <el-table-column prop="email" label="Email"/>
          <el-table-column prop="website" label="网址"/>
          <el-table-column label="操作" width="120" align="center">
            <template slot-scope="scope">
              <img 
                src="@/assets/images/common/edit.png" 
                style="margin-right:20px"
                @click="goRoute(scope.row)"
              />
              <img src="@/assets/images/common/del.png"  @click="comfirm(scope.row)"/>
            </template>
          </el-table-column>
      </el-table>
      <pagination :total="total" :page.sync="page" :size.sync="size"  @getTableData="handleSearch"/>
    </div>
  </div>
</template>

<script>
const pagination = () => import('@/components/pagination.vue')
import { isComfirm } from '@/utils/utils.js'
import { getDistributor,deleteDistributor } from '@/api/soc.js'

export default {
  name: "distribution",
  components:{
    pagination
  },
  data() {
    return {
      tableData:[],
      total:0,
      page:1,
      size:10,
      searchValue:'',
    };
  },
  watch:{
    size(){
      this.handleSearch(1)
    }
  },
  activated(){
    this.handleSearch()
  },
  methods:{
    //查询
    handleSearch(page){
      page ? this.page = page : this.page = 1;
      let params = {
        current:this.page,
        size:this.size,
        name:this.searchValue,
        code:this.searchValue
      }
      getDistributor(params).then(res=>{
        if(res.code==200){
          this.tableData = res.data.records
          this.total = res.data.total
        }else{
          this.$message.error(res.msg || '接口失败')
        }
      })
    },
    //二次确认
    comfirm(row){
      isComfirm(row,this.del)
    },
    //删除
    del(row){
      deleteDistributor(row.id).then(res=>{
        if(res.code==200){
          this.$message.success('删除成功')
          this.handleSearch(this.page)
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    goRoute(row){
      this.$router.push({
        path:'distribution_edit',
        query:row
      })
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/element-reset.scss';
.distribution{
  padding:10px 20px;
  position: relative;
  height: calc(100vh - 140px);
  text-align: left;
  color: #E8E8E8;
  .content{
    position: relative;
    background: #232323;
    min-height: calc(100vh - 250px);
    text-align: left;
    padding: 25px 35px 80px 35px;
    .title{
      font-size: 20px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }
    img{
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
    .tool{
      width: 300px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
